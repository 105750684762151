import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({ providedIn: 'root' })
export class DashboardService extends ApiServiceProvider {


  getDashboardCardDetails(requestPayload) {
    const payload: any = {
      targetedType: requestPayload.targetedType,
      method: requestPayload.method,
      generic: false,
    };
    payload.servicePath = requestPayload.servicePath || '';
    return this.post(payload);
  }

  public getDashBoardOpportunityData(
    orderBy: string,
    viewBy: string,
    perPeriod: boolean,
    viewType: object
  ) {
    let payload = {
      targetedType: 'RFQ',
      formParams: { templateType: 'COMMODITY' },
      payload: {
        filterBy: '',
        limit: '100',
        oppFor: '',
        oppForId: 0,
        orderBy,
        perPeriod,
        viewBy,
        templateType: 'COMMODITY',
        rfxSummaryViewFilter: viewType
      },
      method: 'POST',
      servicePath: 'getDashBoardOpportunityGraphs'
    };
    return this.post(payload);
  }

}
